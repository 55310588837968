
html, body, #root {
height: 100%;
width: 100%;
margin: 0;
padding: 0;
border: none;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App-logo {
  margin: 2%;
  height: 50%;
  pointer-events: none;
}

.privacy-main {
  width: 100%;
}

.App-header {
  background-color: #fd1c70;
  height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-start;
  width: 100%;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  text-align: center;
}

.brain-img {
  text-align: center;
}

.container {
  border-radius: 30px;
  box-shadow: 0px 0px 10px #333;
  align-items: flex-start;
  text-align: left;
  padding: 2.5vw;
  width: 50vw;
  margin: 50px auto 50px auto;
}

@media only screen and (max-width: 1028px) {
  
.container {
  border-radius: 30px;
  box-shadow: 0px 0px 10px #333;
  align-items: flex-start;
  text-align: left;
  padding: 2.5vw;
  width: 90vw;
  margin: 50px auto 50px auto;
}
}

.App-menu {
  margin: 4vmin;
  height: 9vmin;
  pointer-events: none;
}
.App-link {
  color: #61dafb;
}
